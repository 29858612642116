.hover-scale {
  transition: all 0.2s ease-in-out;
}

.hover-scale:hover {
  transform: scale(1.05);
}

.hover-animate {
  transition: all 0.2s ease-in-out;
}

.hover-animate:hover,
.hover-animate:focus {
  transform: translate3d(0, -2px, 0);
}

.hover-scale-bg-image .bg-image {
  transition: all 0.2s ease-in-out;
}

.hover-scale-bg-image:hover .bg-image,
.hover-scale-bg-image:focus .bg-image {
  transform: scale(1.05);
}

.font-smaller {
  font-size: smaller;
}

.font-small {
  font-size: small;
}

.loading-mask:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.3);
}

@media (max-width: 768px) {
  .container-xl.skinny-container {
    padding-right: 0rem;
    padding-left: 0rem;
  }

  .row.skinny-container {
    margin-left: 0;
    margin-right: 0;
  }
}

/* @media (max-width: 540px) {
  .row.skinny-row > * {
    padding-right: 0.1rem;
    padding-left: 0.1rem;
  }
} */
